<template>
  <div>
    <PostsContainer
      :posts="posts"
      :headers="headers"
      :showBtnAddPost="false"
      :authUserRole="'false'"
      :isFilter="true"
      :unreadFeeds="unreadFeeds"
      @showFilter="$emit('showFilter')"
      @getPosts="getPosts"
      @deletePost="onDeletePost"
      @changePostParams="onChangePostParams"
    />
  </div>
</template>
<script>
import PostsContainer from '@/components/PostsContainer.vue';

import postMixin from '@/mixins/posts/postMixin';

export default {
  name: 'ProfileFeed',
  mixins: [postMixin],
  components: { PostsContainer },
  props: {},
  data() {
    return {};
  },
  computed: {
    unreadFeeds() {
      return this.$store.getters.getUnreadFeeds;
    },
  },

  async created() {
    this.$eventBus.$on('loadedGroup', this.getPosts);
    this.$eventBus.$on('addedFeed', this.addFeed);
  },
  methods: {
    addFeed(feed) {
      this.feeds.unshift(feed);

      if (this.feeds.length > 10) {
        this.feeds.pop();
      }
    },
  },
  beforeDestroy() {
    this.$eventBus.$off('loadedGroup', this.getPosts);
    this.$eventBus.$off('addedFeed', this.addFeed);
  },
};
</script>
<style lang="scss" scoped>
</style>
